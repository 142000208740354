<template>
  <div id="data-percentage-container">
    <div class="header" @click="toggleCollapse">
      <h2 class="title">Sensor Percentage</h2>
      <img :src="isCollapsed ? DownArrow : RightArrow" alt="Toggle" class="arrow-icon" />
    </div>

    <transition name="slide">
      <div class="device-row" v-if="!isCollapsed">
        <div class="device-card" v-for="(device, index) in devices" :key="index">
          <div class="title-row">
            <img :src="device.icon" style="margin-right: 5px;" />
            <h6 class="sensor-title">{{ device.title }}</h6>
          </div>
          <h5 class="percentage">{{ device.percentage }}%</h5>
          <span class="last-updated">{{ device.lastUpdated }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable no-case-declarations, no-await-in-loop, no-restricted-syntax */
import GatewayIcon from '@/assets/icons/gateway-icon.svg';
import RPMIcon from '@/assets/icons/rpm-icon.svg';
import GPSIcon from '@/assets/icons/gps-icon.svg';
import FlowmeterIcon from '@/assets/icons/flowmeter-icon.svg';
import AEIcon from '@/assets/icons/ae-icon.svg';
import PowerIcon from '@/assets/icons/power-icon.svg';
import DownArrow from '@/assets/icons/down-arrow.svg';
import RightArrow from '@/assets/icons/right-arrow.svg';
import SeriesSvc from '@/services/SeriesSvc';
import moment from 'moment';

export default {
  name: 'DataPercentage',
  data() {
    return {
      isCollapsed: true,
      DownArrow,
      RightArrow,
      devices: [
      ],
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
  async mounted() {
    const dateFilter = this.$store.getters['filter/date'];
    const start = dateFilter.start;
    const end = dateFilter.end;
    const interval = this.$store.getters['filter/interval'];
    let countRange = end.unix() - start.unix();

    switch (interval) {
      case 'minute':
        countRange /= 60;
        break;
      case 'hour':
        countRange /= 3600;
        break;
      case 'day':
        countRange /= 86400;
        break;
      case 'week':
        countRange /= 604800;
        break;
    }

    countRange = Math.ceil(countRange);

    const currentMassId = this.$store.getters['filter/currentMassId'];

    const assets = this.$store.getters['asset/assets'];
    const currAsset = assets.find((asset) => asset.massId === currentMassId);

    const summary = currAsset.summary || null;
    const devices = currAsset.devices || null;

    // eslint-disable-next-line no-restricted-syntax
    for (const device of devices) {
      let icon = null;
      let dataCount = 0;
      let lastData = '-';
      const type = device.devcType;
      const label = device.devcLabel;

      switch (type) {
        case 'gps':
          icon = GPSIcon;
          break;
        case 'rpm':
          icon = RPMIcon;
          break;
        case 'flowmeter':
          icon = FlowmeterIcon;
          break;
        case 'power':
          icon = PowerIcon;
          break;
        case 'ae':
          icon = AEIcon;
          break;
        case 'temperature':
          icon = PowerIcon;
          break;
        default:
          icon = GatewayIcon;
          break;
      }

      switch (type) {
        case 'gps':
          dataCount = Object.values(summary.gps.data).length;
          lastData = moment(Object.keys(summary.gps.data)[Object.keys(summary.gps.data).length - 1] * 1000).format('YYYY-MM-DD HH:mm');
          break;
        case 'rpm':
          for (const rpm of Object.values(summary.rpm.data)) {
            if (rpm.PORT && rpm.STARBOARD) dataCount += 1;
          }
          lastData = moment(Object.keys(summary.rpm.data)[Object.keys(summary.rpm.data).length - 1] * 1000).format('YYYY-MM-DD HH:mm');
          break;
        case 'flowmeter':
          for (const flowmeter of Object.values(summary.flowmeter.data)) {
            if (flowmeter[label]) dataCount += 1;
          }
          lastData = moment(Object.keys(summary.flowmeter.data)[Object.keys(summary.flowmeter.data).length - 1] * 1000).format('YYYY-MM-DD HH:mm');
          break;
        case 'ae':
          for (const ae of Object.values(summary.ae.data)) {
            if (ae.AE1 && ae.AE2 && ae.AE3) dataCount += 1;
          }
          lastData = moment(Object.keys(summary.ae.data)[Object.keys(summary.ae.data).length - 1] * 1000).format('YYYY-MM-DD HH:mm');
          break;
        default:
          const dataRes = await SeriesSvc.getAggregatedDataByPeriod(type, start.unix(), end.unix(), {
            params: {
              device: [device.devcUniqueId],
              aggregatedUnit: interval.toUpperCase(),
            },
          });
          const data = dataRes?.data?.data?.series || [];
          dataCount = data.length;
          lastData = moment(data[data.length - 1].timestamp * 1000).format('YYYY-MM-DD HH:mm');
          break;
      }
      this.devices.push({
        icon,
        title: label,
        percentage: ((dataCount / countRange) * 100).toFixed(2),
        lastUpdated: lastData,
      });
    }
  },
};
</script>

<style scoped>
#data-percentage-container {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 15px;
  overflow: hidden;
  transition: height 0.3s ease;
}

#data-percentage-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

#data-percentage-container .title {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

#data-percentage-container .device-row > div {
  margin-right: 5px;
}

#data-percentage-container .device-row > div:last-child {
  margin-right: 0;
}
.arrow-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

#data-percentage-container .device-row {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

#data-percentage-container .device-card {
  border-radius: 12px;
  height: 80px;
  min-width: 145px;
  border-color: #E8ECF3;
  border-width: 1px;
  border-style: solid;
  padding-top: 5px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}

.device-card .title-row {
  display: flex;
  align-items: center;
}

.device-card .title-row .sensor-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
}

.device-card .percentage {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}

.device-card .last-updated {
  font-size: 10px;
  font-weight: 500;
  line-height: 22px;
  color: #737791;
}

.slide-enter-active, .slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter, .slide-leave-to {
  opacity: 0;
  height: 0;
}
</style>
