<template>
  <div id="map" v-if="SelectedMap === 'default'"></div>
  <div style="height: 100%;" v-else-if="SelectedMap === 'windy'">
    <button class="toggle-btn btn btn-sm btn-secondary" id="toggle-btn">Weather Maps Options</button>
    <div div id="button-container" class="hidden">
      <div class="button-row">
        <span class="button-label">Current Unit:</span>
        <button class="btn btn-sm btn-primary" id="btn-metric">kt</button>
      </div>
      <hr />
      <div class="button-row">
        <span class="button-label">Num Direction:</span>
        <button class="btn btn-sm btn-primary" id="btn-num-direction">off</button>
      </div>
      <hr />
      <div class="button-row">
        <span class="button-label">Graticule:</span>
        <button class="btn btn-sm btn-primary" id="btn-graticule">off</button>
      </div>
      <hr />
      <div class="button-row">
        <span class="button-label">Lat Long:</span>
        <button class="btn btn-sm btn-primary" id="btn-latlon">on</button>
      </div>
      <hr />
      <div class="button-row">
        <span class="button-label">Particle Animation:</span>
        <button class="btn btn-sm btn-primary" id="btn-particles-anim">on</button>
      </div>
      <hr />
      <div class="button-row">
        <span class="button-label">Isolines:</span>
        <button class="btn btn-sm btn-primary" id="btn-isolines">off</button>
      </div>
    </div>
    <div id="windy" style="height: 100%;"></div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable new-cap */

export default {
  name: 'Map',
  props: {
    ships: {
      type: Array,
      required: true,
    },
    SelectedMap: {
      type: String,
      required: false,
      default: 'default',
    },
    Options: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      trail: {},
      dotTrail: {},
      shipsLayerGroups: null,
      map: null,
      currentPopupPage: 1,
      currentDotTrailPopupPage: 1,
      totalPopupPages: 2,
      trailCheckboxStatus: {},
    };
  },
  mounted() {
    if (this.SelectedMap === 'default') {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://unpkg.com/leaflet@1.4.0/dist/leaflet.css';
      document.head.appendChild(link);

      const link2 = document.createElement('link');
      link2.rel = 'stylesheet';
      link2.href = 'https://ppete2.github.io/Leaflet.PolylineMeasure/Leaflet.PolylineMeasure.css';
      document.head.appendChild(link2);

      const mapElement = document.getElementById('map');

      if (mapElement && mapElement._leaflet_id) {
        console.log('Leaflet map has been initialized.');
      } else {
        this.initMap();
      }
    } else if (this.SelectedMap === 'windy') {
      const link = document.querySelector(
        'link[href*="leaflet@1.4.0/dist/leaflet.css"]',
      );
      if (link) {
        link.parentNode.removeChild(link);
      }

      const link2 = document.querySelector(
        'link[href*="Leaflet.PolylineMeasure/Leaflet.PolylineMeasure.css"]',
      );
      if (link2) {
        link2.parentNode.removeChild(link2);
      }

      this.initWindyMap();
    }
  },
  beforeDestroy() {
    this.destroyMap();
  },
  methods: {
    handleClickDashboard(massId) {
      try {
        this.$store.dispatch('filter/setCurrentMassId', massId);
        this.$router.push({ name: 'FmsDashboard' });
      } catch (e) {
        console.error('handleClickDashboard - error', e);
      }
    },

    handleClickReport(massId) {
      try {
        this.$store.dispatch('filter/setCurrentMassId', massId);
        this.$router.push({ name: 'FmsReport' });
      } catch (e) {
        console.error('handleClickReport - error', e);
      }
    },

    handleClickVoyage(massId) {
      try {
        this.$store.dispatch('filter/setCurrentMassId', massId);
        this.$router.push({ name: 'VoyageReport' });
      } catch (e) {
        console.error('handleClickReport - error', e);
      }
    },

    initWindyMap() {
      try {
        L.map('windy').setView([0, 0], 2);

        windyInit(
          {
            key: 'R1CTVY4LQHEtYMnM4GPV8WAFq17eFPHg',
            lat: 0,
            lon: 0,
            zoom: 10,
            verbose: false,
            englishLabels: true,
            lang: 'en',
          },
          (windyAPI) => {
            const map = windyAPI.map;
            const picker = windyAPI.picker;
            const store = windyAPI.store;
            const overlays = windyAPI.overlays;
            const broadcast = windyAPI.broadcast;

            // Initialize numDirection
            store.set('numDirection', false);
            let numDirection = store.get('numDirection');
            document.getElementById('btn-num-direction').addEventListener('click', (button) => {
              picker.close();
              numDirection = !numDirection;
              store.set('numDirection', numDirection);
              button.target.innerText = `${numDirection ? 'on' : 'off'}`;
            });

            // Initialize graticule
            store.set('graticule', false);
            let graticule = store.get('graticule');
            document.getElementById('btn-graticule').addEventListener('click', (button) => {
              graticule = !graticule;
              store.set('graticule', graticule);
              button.target.innerText = `${graticule ? 'on' : 'off'}`;
            });

            // Initialize latlon
            store.set('latlon', true);
            let latlon = store.get('latlon');
            document.getElementById('btn-latlon').addEventListener('click', (button) => {
              picker.close();
              latlon = !latlon;
              store.set('latlon', latlon);
              button.target.innerText = `${latlon ? 'on' : 'off'}`;
            });

            // Initialize particlesAnim
            store.set('particlesAnim', 'on');
            const availableParticlesAnim = store.getAllowed('particlesAnim');
            let particlesAnim = store.get('particlesAnim');
            document.getElementById('btn-particles-anim').addEventListener('click', (button) => {
              const idx = availableParticlesAnim.indexOf(particlesAnim);
              particlesAnim = availableParticlesAnim[(idx + 1) % availableParticlesAnim.length];
              store.set('particlesAnim', particlesAnim);
              button.target.innerText = `${particlesAnim}`;
            });

            // Initialize isolines
            store.set('isolines', 'off');
            const availableIsolines = store.getAllowed('isolines');
            let isolines = store.get('isolines');
            document.getElementById('btn-isolines').addEventListener('click', (button) => {
              const idx = availableIsolines.indexOf(isolines);
              isolines = availableIsolines[(idx + 1) % availableIsolines.length];
              store.set('isolines', isolines);
              button.target.innerText = `${isolines}`;
            });

            let currentOverlay = 'wind';

            broadcast.on('redrawFinished', (params) => {
              currentOverlay = params.overlay;
              document.getElementById('btn-metric').innerText = `${overlays[currentOverlay].metric}`;
            });

            document.getElementById('btn-metric').addEventListener('click', (button) => {
              picker.close();
              overlays[currentOverlay].cycleMetric();
              button.target.innerText = `${overlays[currentOverlay].metric}`;
            });

            this.map = map;

            map.on('click', (e) => {
              const { lat, lng } = e.latlng;
              picker.open({ lat, lon: lng });
            });

            this.addShipsToMap();

            // Toggle button functionality
            document.getElementById('toggle-btn').addEventListener('click', () => {
              picker.close();
              const buttonContainer = document.getElementById('button-container');
              buttonContainer.classList.toggle('hidden');
            });
          },
        );
      } catch (error) {
        console.error('Error initializing map:', error);
      }
    },

    initMap() {
      this.map = null;

      const map = L.map('map').setView([-6.2, 106.816666], 13);
      map.setMinZoom(5);
      map.setMaxZoom(19);

      L.control.polylineMeasure(this.getPolylineMeasureOptions()).addTo(map);
      const gebcoLayer = L.tileLayer.wms('https://wms.gebco.net/mapserv?', {
        layers: 'GEBCO_LATEST',
        format: 'image/png',
        transparent: true,
        version: '1.3.0',
        crs: L.CRS.EPSG4326,
      });

      const googleLayer = L.tileLayer('https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&gl=id&hl=id', {
        maxZoom: 19,
        attribution: 'Map data © <a hrefc="https://www.google.com/maps">Google Maps</a>',
      });

      const googleLayerSat = L.tileLayer('https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}&gl=id&hl=id', {
        maxZoom: 19,
        attribution: 'Map data © <a href="https://www.google.com/maps">Google Maps</a>',
      });

      googleLayer.addTo(map);

      const layers = [googleLayerSat, googleLayer, gebcoLayer];
      let activeLayerIndex = localStorage.getItem('activeLayerIndex') || 0;

      const changeMapControl = L.Control.extend({
        onAdd() {
          const button = L.DomUtil.create('button', 'leaflet-change-map-button');
          button.innerHTML = 'Change Map';

          button.onclick = () => {
            map.removeLayer(layers[activeLayerIndex]);

            activeLayerIndex = (activeLayerIndex + 1) % layers.length;

            layers[activeLayerIndex].addTo(map);

            localStorage.setItem('activeLayerIndex', activeLayerIndex);
          };

          return button;
        },
        onRemove() { },
      });

      map.addControl(new changeMapControl({ position: 'topleft' }));
      this.map = map;
      this.shipsLayerGroups = {};
      this.addShipsToMap();
    },

    getPolylineMeasureOptions() {
      const options = {
        position: 'topleft',
        unit: 'kilometres',
        useSubunits: true,
        clearMeasurementsOnStop: false,
        showBearings: true,
        bearingTextIn: 'In',
        bearingTextOut: 'Out',
        tooltipTextFinish: 'Click to <b>finish line</b><br>',
        tooltipTextDelete: 'Press SHIFT-key and click to <b>delete point</b>',
        tooltipTextMove: 'Click and drag to <b>move point</b><br>',
        tooltipTextResume: '<br>Press CTRL-key and click to <b>resume line</b>',
        tooltipTextAdd: 'Press CTRL-key and click to <b>add point</b>',
        measureControlTitleOn: 'Turn on PolylineMeasure',
        measureControlTitleOff: 'Turn off PolylineMeasure',
        measureControlLabel: '&#8614;',
        measureControlClasses: [],
        showClearControl: true,
        clearControlTitle: 'Clear Measurements',
        clearControlLabel: '&times',
        clearControlClasses: [],
        showUnitControl: true,
        unitControlUnits: ['kilometres', 'landmiles', 'nauticalmiles'],
        unitControlTitle: {
          text: 'Change Units',
          kilometres: 'kilometres',
          landmiles: 'land miles',
          nauticalmiles: 'nautical miles',
        },
        unitControlLabel: {
          metres: 'm',
          kilometres: 'km',
          feet: 'ft',
          landmiles: 'mi',
          nauticalmiles: 'nm',
        },
        unitControlClasses: [],
        tempLine: {
          color: '#00f',
          weight: 2,
        },
        fixedLine: {
          color: '#006',
          weight: 2,
        },
        arrow: {
          color: '#000',
        },
        startCircle: {
          color: '#000',
          weight: 1,
          fillColor: '#0f0',
          fillOpacity: 1,
          radius: 3,
        },
        intermedCircle: {
          color: '#000',
          weight: 1,
          fillColor: '#ff0',
          fillOpacity: 1,
          radius: 3,
        },
        currentCircle: {
          color: '#000',
          weight: 1,
          fillColor: '#f0f',
          fillOpacity: 1,
          radius: 6,
        },
        endCircle: {
          color: '#000',
          weight: 1,
          fillColor: '#f00',
          fillOpacity: 1,
          radius: 3,
        },
      };
      return options;
    },

    addShipsToMap() {
      this.ships.forEach((ship) => {
        const shipLayerGroup = L.layerGroup().addTo(this.map);
        this.shipsLayerGroups[ship.massId] = shipLayerGroup;

        const headingMarker = this.createShipHeadingMarker(ship);
        const marker = this.createShipMarker(ship);

        shipLayerGroup.addLayer(headingMarker);
        shipLayerGroup.addLayer(marker);

        marker.bindPopup(this.createPopupContent(ship));

        marker.on('popupopen', (e) => {
          this.updatePopupContent(e.popup, ship);
        });
      });

      this.fitMapToBounds();
    },

    createShipMarker(ship) {
      const marker = L.marker(ship.lastLatLng, {
        icon: ship.icon,
      });

      const tooltipClass = ship.dataStatus && ship.lastGpsData ? 'ship-online' : 'ship-offline';
      marker.bindTooltip(
        `<div class="ship-label ${tooltipClass}">${ship.massName}</div>`,
        {
          permanent: true,
          offset: [20, 10],
        },
      ).openTooltip();

      if (this?.Options?.enableTrail === 'true' || this?.Options?.enableTrail === true) {
        this.toggleTrail(true, ship);
      }

      return marker;
    },

    createShipHeadingMarker(ship) {
      const marker = L.marker(ship.lastLatLng, {
        icon: ship.headingIcon,
        rotationAngle: ship.lastGpsData.track || 0,
        rotationOrigin: '50% 50%',
      });

      return marker;
    },

    fitMapToBounds() {
      if (this.ships.length === 1) {
        this.map.setView(this.ships[0].lastLatLng, 9);
      } else {
        const group = new L.featureGroup(
          this.ships.map((ship) => L.marker(ship.lastLatLng)),
        );
        this.map.fitBounds(group.getBounds(), { padding: [100, 100] });
      }
    },

    addEventListener(popup, ship) {
      this.setupButtonEvent(`report-${ship.massId}`, () => this.handleClickReport(ship.massId));
      this.setupButtonEvent(`dashboard-${ship.massId}`, () => this.handleClickDashboard(ship.massId));
      this.setupButtonEvent(`voyage-${ship.massId}`, () => this.handleClickVoyage(ship.massId));
      this.addPopupPaginationButtonEvent(popup, ship);
      this.addTrailButtonEvent(popup, ship);
    },

    setupButtonEvent(buttonId, callback) {
      const button = document.getElementById(buttonId);
      if (button) {
        button.removeEventListener('click', callback);
        button.addEventListener('click', callback);
      }
    },

    addTrailButtonEvent(popup, ship) {
      const trailSwitch = document.getElementById(`trail-switch-${ship.massId}`);
      if (trailSwitch) {
        trailSwitch.checked = this.trailCheckboxStatus[ship.massId];
        trailSwitch.removeEventListener('change', this.trailSwitchChangeHandler);
        this.trailSwitchChangeHandler = (event) => {
          this.toggleTrail(event.target.checked, ship);
          this.updatePopupContent(popup, ship);
        };
        trailSwitch.addEventListener('change', this.trailSwitchChangeHandler);
      }
    },

    toggleTrail(isChecked, ship) {
      const coordinates = ship.coordinates.filter(
        (x) => x[0] !== undefined && x[1] !== undefined,
      );
      this.trailCheckboxStatus[ship.massId] = isChecked;
      if (isChecked) {
        const trailLayer = L.polyline(coordinates, {
          color: ship.dataStatus ? '#28F385' : '#FF3B30',
          weight: 4,
        });
        this.shipsLayerGroups[ship.massId].addLayer(trailLayer);
        this.trail[ship.massId] = trailLayer;
        trailLayer.addTo(this.map);
        this.map.fitBounds(trailLayer.getBounds());
        this.toggleDotTrail(true, ship);
      } else if (this.trail[ship.massId]) {
        this.map.removeLayer(this.trail[ship.massId]);
        this.shipsLayerGroups[ship.massId].removeLayer(this.trail[ship.massId]);
        delete this.trail[ship.massId];
        this.toggleDotTrail(false, ship);
      }
    },

    addDotTrailButtonEvent(ship) {
      const dotTrailSwitch = document.getElementById(
        `dot-trail-switch-${ship.massId}`,
      );
      if (dotTrailSwitch) {
        dotTrailSwitch.addEventListener('change', (event) => {
          this.toggleDotTrail(event.target.checked, ship);
        });
      }
    },

    toggleDotTrail(isChecked, ship) {
      if (isChecked) {
        const coordinatesData = ship.coordinatesData.filter(
          (x) => x.latLong[0] !== undefined && x.latLong[1] !== undefined,
        );
        this.dotTrail[ship.massId] = this.dotTrail[ship.massId] || [];
        coordinatesData.forEach((data) => {
          const marker = L.marker(data.latLong, {
            icon: ship.markerIcon,
            rotationAngle: data.heading || 0,
            rotationOrigin: '50% 50%',
          });

          marker.bindPopup(this.createDotTrailPopupContent(ship, data));
          marker.on('popupopen', (e) => {
            e.target.setPopupContent(this.createDotTrailPopupContent(ship, data, this.currentDotTrailPopupPage));
            this.setupDotTrailPaginationButtons(marker, ship, data);
          });

          this.dotTrail[ship.massId].push(marker);
          this.shipsLayerGroups[ship.massId].addLayer(marker);
          marker.addTo(this.map);
        });
      } else {
        if (this.dotTrail[ship.massId]) {
          this.dotTrail[ship.massId].forEach((marker) => {
            this.map.removeLayer(marker);
            this.shipsLayerGroups[ship.massId].removeLayer(marker);
          });
        }

        delete this.dotTrail[ship.massId];
      }
    },

    addPopupPaginationButtonEvent(popup, ship) {
      this.setupButtonEvent(`previous-popup-button-${ship.massId}`, () => {
        this.previousPopupPage();
        this.updatePopupContent(popup, ship);
      });
      this.setupButtonEvent(`next-popup-button-${ship.massId}`, () => {
        this.nextPopupPage();
        this.updatePopupContent(popup, ship);
      });
    },

    updatePopupContent(popup, ship) {
      const newPopup = this.createPopupContent(ship);
      popup.setContent(newPopup);
      this.addPopupPaginationButtonEvent(popup, ship);
      this.addTrailButtonEvent(popup, ship);
      this.renderTrailSwitches(ship);
      this.addDotTrailButtonEvent(ship);
      this.addEventListener(popup, ship);
    },

    createPopupContent(ship) {
      const content = `
          <div class="row pt-3">
            <div class="col-6 d-flex align-items-center">
              <h5>${ship.massName || '-'}</h5>
            </div>
            <div class="col-6 d-flex justify-content-end align-items-center pt-0">
              <h5 class="${ship.dataStatus ? 'status online' : 'status offline'}">
                ${ship.dataStatus ? 'online' : 'offline'}
              </h5>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-12">
              <p style="margin: 0; font-size: 14px"><b>Last Data: </b> ${new Date(ship.lastGpsData?.timestamp * 1000 || 0).toLocaleString()}</p>
            </div>
          </div>
          <hr />
          <div class="row info-section pb-2">
            ${this.currentPopupPage === 1 ? this.renderFirstPageInfo(ship) : this.renderSecondPageInfo(ship)}
          </div>
          <hr />
          <div class="row pb-3">
            ${this.renderTrailSwitches(ship)}
          </div>
          <hr />
          <div class="row button-section">
            ${this.renderActionButtons(ship)}
          </div>
        `;
      return content;
    },

    renderFirstPageInfo(ship) {
      const content = `
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px; color: #1e1e1e">Location</p>
            <p style="font-weight: 1000; color: #1e1e1e">${ship.location || '-'}</p>
          </div>
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px">Total Cruise</p>
            <p style="font-weight: 1000; color: #1e1e1e">${ship.summary?.gps?.summary?.totalDistance ? (ship.summary.gps.summary.totalDistance / 1852).toFixed(2) : '-'} NM</p>
          </div>
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px">Avg. Speed</p>
            <p style="font-weight: 1000; color: #1e1e1e">${ship.summary?.gps?.summary?.averageSpeed ? (ship.summary.gps.summary.averageSpeed / 1.852).toFixed(2) : '-'} knot</p>
          </div>
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px">Current AE</p>
            <p style="font-weight: 1000; color: #1e1e1e">AE ${ship.lastAeData?.find((ae) => ae.rpm > 0)?.no + 1 || '-'}</p>
          </div>
        `;
      return content;
    },

    renderSecondPageInfo(ship) {
      const content = `
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px; color: #1e1e1e">Port RPM</p>
            <p style="font-weight: 1000; color: #1e1e1e">${Math.floor(ship.lastRpmData?.find((rpm) => rpm.type === 'PORT')?.rpm || 0)}</p>
          </div>
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px">Starboard RPM</p>
            <p style="font-weight: 1000; color: #1e1e1e">${Math.floor(ship.lastRpmData?.find((rpm) => rpm.type === 'STARBOARD')?.rpm || 0)}</p>
          </div>
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px">Port In Flowrate</p>
            <p style="font-weight: 1000; color: #1e1e1e">${Math.floor(ship.lastFmData?.find((fm) => fm.deviceId.split('-').pop() === '1')?.volumeFlowrate || 0)} L/h</p>
          </div>
          <div class="col-6" style="height: 50px">
            <p style="margin-top: 0; margin-bottom: -10px">Starboard In Flowrate</p>
            <p style="font-weight: 1000; color: #1e1e1e">${Math.floor(ship.lastFmData?.find((fm) => fm.deviceId.split('-').pop() === '3')?.volumeFlowrate || 0)} L/h</p>
          </div>
        `;
      return content;
    },

    renderTrailSwitches(ship) {
      const isChecked = this.trailCheckboxStatus[ship.massId];
      const content = `
          <div class="col-6" style="height: 15px">
            <div class="custom-control custom-switch mr-2">
              <input type="checkbox" class="custom-control-input" id="trail-switch-${ship.massId}" ${isChecked ? 'checked' : ''} />
              <label class="custom-control-label" for="trail-switch-${ship.massId}">Vessel Trail</label>
            </div>
            ${isChecked ? `
              <div class="custom-control custom-switch mr-2">
                <input type="checkbox" class="custom-control-input" id="dot-trail-switch-${ship.massId}" ${this.dotTrail[ship.massId]?.length > 0 ? 'checked' : ''} />
                <label class="custom-control-label" for="dot-trail-switch-${ship.massId}">Dot Trail</label>
              </div>
            ` : ''}
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button class="btn btn-sm btn-outline-mantraz mr-1" id="previous-popup-button-${ship.massId}" ${this.currentPopupPage === 1 ? 'disabled' : ''}>Previous</button>
            <button class="btn btn-sm btn-outline-mantraz" id="next-popup-button-${ship.massId}" ${this.currentPopupPage === this.totalPopupPages ? 'disabled' : ''}>Next</button>
          </div>
        `;
      return content;
    },

    renderActionButtons(ship) {
      let content = `
          <div class="col-6 my-1">
            <button class="btn btn-sm btn-block btn-mantraz tooltip-button" id="report-${ship.massId}">View Report</button>
          </div>
          <div class="col-6 my-1">
            <button class="btn btn-sm btn-block btn-outline-mantraz tooltip-button" id="dashboard-${ship.massId}">View Dashboard</button>
          </div>
        `;
      if (!this?.options?.hideVoyageButton === true || !this?.options?.hideVoyageButton === 'true') {
        content += `
          <div class="col-12 my-1">
            <button class="btn btn-sm btn-block btn-mantraz tooltip-button" id="voyage-${ship.massId}">Voyage Report</button>
          </div>
        `;
      }
      return content;
    },

    previousPopupPage() {
      if (this.currentPopupPage > 1) {
        this.currentPopupPage--;
      }
    },

    nextPopupPage() {
      if (this.currentPopupPage < this.totalPopupPages) {
        this.currentPopupPage++;
      }
    },

    createDotTrailPopupContent(ship, data) {
      const location = '-';
      const totalCruise = data?.totalCruise ? (data?.totalCruise / 1852).toFixed(2) : '-';
      const avgSpeed = data?.avgSpeed ? (data?.avgSpeed * 0.5399568034557236).toFixed(2) : '-';
      const currentAE = data?.currentAe || '-';
      const portRPM = data?.portRpm?.toFixed(2) || '-';
      const starboardRPM = data?.starboardRpm?.toFixed(2) || '-';
      const portInFlowrate = data?.portInFlowrate?.toFixed(2) || '-';
      const starboardInFlowrate = data?.starboardInFlowrate?.toFixed(2) || '-';
      let content = `
          <hr />
          <div class="row">
            <div class="col-12">
              <p style="margin: 0; font-size: 14px"><b>Datetime: </b> ${new Date(data.gpsData.timestamp * 1000 || 0).toLocaleString()}</p>
            </div>
          </div>
          <hr />
          <div class="row info-section pb-2">
        `;

      if (this.currentDotTrailPopupPage === 1) {
        content += `
            <div class="col-6" style="height: 50px">
              <p style="margin-top: 0; margin-bottom: -10px; color: #1e1e1e">Location</p>
              <p style="font-weight: 1000; color: #1e1e1e">${location}</p>
            </div>
            <div class="col-6" style="height: 50px">
              <p style="margin-top: 0; margin-bottom: -10px">Total Cruise</p>
              <p style="font-weight: 1000; color: #1e1e1e">${totalCruise} NM</p>
            </div>
            <div class="col-6" style="height: 50px">
              <p style="margin-top: 0; margin-bottom: -10px">Avg. Speed</p>
              <p style="font-weight: 1000; color: #1e1e1e">${avgSpeed} knot</p>
            </div>
            <div class="col-6" style="height: 50px">
              <p style="margin-top: 0; margin-bottom: -10px">Current AE</p>
              <p style="font-weight: 1000; color: #1e1e1e">AE ${currentAE}</p>
            </div>
          `;
      } else if (this.currentDotTrailPopupPage === 2) {
        content += `
            <div class="col-6" style="height: 50px">
              <p style="margin-top: 0; margin-bottom: -10px; color: #1e1e1e">Port RPM</p>
              <p style="font-weight: 1000; color: #1e1e1e">${portRPM}</p>
            </div>
            <div class="col-6" style="height: 50px">
              <p style="margin-top: 0; margin-bottom: -10px">Starboard RPM</p>
              <p style="font-weight: 1000; color: #1e1e1e">${starboardRPM}</p>
            </div>
            <div class="col-6" style="height: 50px">
              <p style="margin-top: 0; margin-bottom: -10px">Port In Flowrate</p>
              <p style="font-weight: 1000; color: #1e1e1e">${portInFlowrate} L/h</p>
            </div>
            <div class="col-6" style="height: 50px">
              <p style="margin-top: 0; margin-bottom: -10px">Starboard In Flowrate</p>
              <p style="font-weight: 1000; color: #1e1e1e">${starboardInFlowrate} L/h</p>
            </div>
          `;
      }

      content += `
          </div>
          <div class="row">
            <div class="col-6">
              <button class="btn btn-sm btn-outline-mantraz" id="previous-popup-button-${ship.massId}-${data.timestamp}" ${this.currentDotTrailPopupPage === 1 ? 'disabled' : ''}>Previous</button>
            </div>
            <div class="col-6 text-right">
              <button class="btn btn-sm btn-outline-mantraz" id="next-popup-button-${ship.massId}-${data.timestamp}" ${this.currentDotTrailPopupPage === 2 ? 'disabled' : ''}>Next</button>
            </div>
          </div>
        `;

      return content;
    },

    setupDotTrailPaginationButtons(marker, ship, data) {
      const previousButton = document.getElementById(`previous-popup-button-${ship.massId}-${data.timestamp}`);
      const nextButton = document.getElementById(`next-popup-button-${ship.massId}-${data.timestamp}`);

      if (previousButton) {
        previousButton.onclick = () => {
          if (this.currentDotTrailPopupPage > 1) {
            this.currentDotTrailPopupPage--;
            marker.setPopupContent(this.createDotTrailPopupContent(ship, data, this.currentDotTrailPopupPage));
            this.setupDotTrailPaginationButtons(marker, ship, data, this.currentDotTrailPopupPage);
          }
        };
      }

      if (nextButton) {
        nextButton.onclick = () => {
          if (this.currentDotTrailPopupPage < 2) {
            this.currentDotTrailPopupPage++;
            marker.setPopupContent(this.createDotTrailPopupContent(ship, data, this.currentDotTrailPopupPage));
            this.setupDotTrailPaginationButtons(marker, ship, data, this.currentDotTrailPopupPage);
          }
        };
      }
    },

    destroyMap() {
      if (this.map) {
        this.map.off();
        this.map.remove();
        this.map = null;
      }

      Object.values(this.shipsLayerGroups).forEach((layerGroup) => {
        layerGroup.clearLayers();
      });
      this.shipsLayerGroups = {};
    },
  },
};
</script>

<style>
#map {
  height: 400px;
  /* Atur tinggi peta */
  width: 100%;
  /* Atur lebar peta */
}

.ship-label {
  font-size: 13px;
  font-weight: 1000;
  text-align: center;
  color: #ffffff;
  padding: 3px 5px;
  border-radius: 5px;
  /* overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px; */
}

.ship-online {
  background-color: #16b961;
}

.ship-offline {
  background-color: #ff3b30;
}

.leaflet-popup-content-wrapper {
  border-radius: 10px;
  border: 1px solid #ffffff;
  background-color: #ffffff !important;
  color: #1e1e1e;
  font-weight: 500;
  width: 340px;
  padding: 10px;
}

.status {
  margin-left: 10px;
  padding: 1px 5px;
  border-radius: 5px;
  font-weight: 600;
}

.online {
  background-color: #d7f0d8;
  color: #38ac3d;
}

.offline {
  background-color: #ffe4e4;
  color: #f24848;
}

.tooltip-button {
  width: 100%;
  font-weight: 700;
}

hr {
  margin: 0;
}

.btn-outline-mantraz {
  border-color: #ff69b4 !important;
  color: #ff69b4 !important;
}

.btn-outline-mantraz:hover {
  background-color: #ff69b4 !important;
  color: #fff !important;
}

.btn-mantraz {
  background-color: #ff69b4 !important;
  color: #fff !important;
}

.leaflet-change-map-button {
  background-color: #fff;
  border-radius: 2px;
  padding: 5px;
  cursor: pointer;
  font-size: 13px;
  box-shadow: none;
  font-weight: 500;
}
</style>
