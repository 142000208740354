import axios from 'axios';

export default {

  // Device Log
  getDeviceLogs(config) {
    return axios.get('/master/device_log', config);
  },
  getLogInformation(param) {
    return axios.get(`/master/device_log?findField=${param.findField}&findValue=${param.findValue}&page=${param.page}`);
  },
  getLogInformationByDate(param) {
    return axios.get(`/master/device_log?findField=${param.findField}&findValue=${param.findValue}&page=${param.page}`);
  },
  addDeviceLog(data) {
    return axios.post('/master/device_log', data);
  },
  updateDeviceLog(data, id) {
    return axios.put(`/master/device_log/${id}`, data);
  },
  deleteLogDevice(id) {
    return axios.delete(`/master/device_log/${id}`);
  },

  // Device
  getDevices(config) {
    return axios.get('/master/device', config);
  },
  getDevicesByAssetId(param) {
    return axios.get(`/master/device?findField=${param.findField}&findValue=${param.findValue}`);
  },
  getDeviceByMassId(massId) {
    return axios.get(`/master/device?findField=devcMassId&findValue=${massId}&limit=100`);
  },
  addDevice(data) {
    return axios.post('/master/device', data);
  },
  updateDevice(data, id) {
    return axios.put(`/master/device/${id}`, data);
  },
  deleteDevice(id) {
    return axios.delete(`/master/device/${id}`);
  },

  // Series
  getSeriesDeviceWeekly(config) {
    return axios.get('/series/base/last/1/week', config);
  },
  getSeriesDevicesWeekly(devices) {
    const config = {
      filter: {
        device: devices,
      },
    };
    return axios.get('/series/base/last/1/week', config);
  },
};
